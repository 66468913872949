@use './../../scss/' as *;
.hero-slider {
    margin-top: 150px;
    padding-top: 85px 0;
    @include tablet {
        padding: 0;
    }

    &.style-2 {
        padding: 116px 0 327px;

        @include mobile {
            padding: 50px 0 100px;
        }
    }

    // background: url('../../../assets/images/shape/shape-01.png') no-repeat;
}

.home-3 .hero-slider {
    padding-top: 69px;
    .block-text {      
        padding-top: 50px;
        .title {
            font-size: 80px;
        }
        .sub-title {
            color: black;
           
        }
        .desc {
            color: black;
        }
    }
    @include mobile {
        text-align: center;  

        padding-top: 100px;
        .block-text {
            .title {
                font-size: 50px;
                
            }
            .sub-title {
                &::after {
                    left: 45%;
                }
            }
        }
    }
}

.section-about {
    padding-top: 180px!important;
    .block-text {
        margin-top: -60px;        
        @include mobile {
            text-align: center;
            .sub-title {
                &::after {
                    left: 45%;
                }
            }
        }
    }
    padding: 170px 0;
    @include tablet {
        padding: 100px 0;
    }
    .group-image {
        display: flex;
        align-items: center;
        @include tablet {
            padding-top: 50px;
        }
        @include mobile {
            flex-wrap: wrap;
            justify-content: space-around; 
            padding-top: 30px;
        }

        .left {
            margin-right: 30px;

            @include mobile {
                margin-bottom: 30px;
                margin-right: 0;
            }
        }
        .right .item:nth-child(1) {
            margin-bottom: 30px;
        }
    }
}



.page {
    background: url('./../../assets/images/background/bg-about.jpg');
}

.about .section-about {
    padding: 168px 0;
}


.home-3 .section-about {
    padding: 90px 0 0;
    margin-bottom: 60px;
    @include mobile {
        margin-bottom: 0px;
        padding: 0;
    }


    .group-image {
        margin-top: 42px;
        align-items: flex-start;
        position: relative;
        .left {
            margin-right: 23px;
        }
        .item.bg-3 {
            position: absolute;
            bottom: -260px;
            right: 110px;

            @include mobile {
                position: relative;
                right: auto;
                bottom: 0;
            }
        }
        .item.bg-2 {
            position: absolute;
            bottom: 50px;
            right: 110px;

            @include mobile {
                position: relative;
                right: auto;
                bottom: 0;
            }
        }
        .item.bg-1 {
            position: relative;
            bottom: -80px;
            right: 10px;

            @include mobile {
                position: relative;
                right: auto;
                bottom: 0;
            }
        }
       
    }
    @include tablet {
        padding: 40px 0 120px;
    }

    @include mobile {
        padding: 40px 0 80px;
    }
}
.products {
    padding: 150px 0 0px;

    @include tablet {
        padding: 100px 0;
    }
    .container-products {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 40px;
        margin: auto;
        max-width: 1400px;
        margin-bottom: 80px;
        @include mobile {
            grid-template-columns: 1fr;
            padding-left: 35px;
            padding-right: 35px;
            gap: 24px;
        }
        
    }
    .single-product {
        text-align: center;
        padding: 20px 0px 30px 0px;
        border-radius: 10px;
        background-color: #fff;
        border: #e3014d solid 2px;
        width: 100%;
        display: grid;
        grid-template-columns: 2fr 1fr;
        grid-template-areas: 
            "title title"
            "img vol1"
            "img vol2"
            "img vol3"
            "img vol4";  
            @include mobile {
                padding: 5px 0 10px 0;
            }    
        }
        .title-product {
            grid-area: title;
            font-size: 32px;
            padding-bottom: 10px;
            color: $secondary-color;
            @include mobile {
                font-size: 27px;
                padding-bottom: 0;
            }
        }
        .img-product {
            grid-area: img;
            margin: auto;
            border-radius: 8px;
            width: 85%;
            @include mobile {
                width: 65%;
            }
        }
        .vol-product1 {
            padding-top: 20px;
            grid-area: vol1;
            text-align: start;
            margin-left: -10px;
            font-size: 21px;
            font-weight: 800;
            color: black;

            @include mobile {
                font-size: 19px;
                margin-left: -20px;
            }

        }
        .vol-product2 {
            grid-area: vol2;
            text-align: start;
            margin-left: -10px;
            font-size: 21px;
            font-weight: 800;
            color: black;

            @include mobile {
                font-size: 19px;
                margin-left: -20px;
            }
        }
        .vol-product3 {
            grid-area: vol3;
            text-align: start;
            margin-left: -10px;
            font-size: 21px;
            font-weight: 800; 
            color: black;
           
            @include mobile {
            font-size: 19px;
            margin-left: -20px;
            }

        }
        .vol-product4 {
            grid-area: vol4;
            text-align: start;
            margin-left: -10px;
            font-size: 21px;
            font-weight: 800;
            color: black;

            @include mobile {
                font-size: 19px;
                margin-left: -20px;
            }

        }
        .extra-product {
            margin-right: -30px;
            @include mobile {
                margin-right: -20px;
            }
        }
    }


.section-info {
    padding: 158px 0 0;
}

.about .portfolio  {
    padding: 147px 0 0;
    @include mobile {
        padding: 100px 0 0;
    }
}

.montono {
    padding: 0 6px;
}

.road-map {
    padding: 113px 0px 160px;

    @include tablet {
        padding: 100px 0;
    }
}

.home-3 .montono {
    padding: 140px 0 0;
}
.home-3 .road-map {
    padding: 138px 0px 0;
}

.roadmap .road-map {
    padding: 143px 0px 160px;
}

.service .road-map {
    padding: 144px 0px 11px;
}

.newsletter {
    .newsletter__body {
        text-align: center;        
        background: $secondary-color;
        padding: 51px 72px 47px;
        border-radius: 20px;
        color: #fff;
        margin-top: 80px;
        margin-bottom: -40px;

        @include tablet {
            flex-wrap: wrap;

            .body__form {
                width: 100% !important;
                padding-left: 0 !important;
                padding-top: 30px;
            }
        }

        @include mobile {
            padding: 51px 30px 47px;
        }

        .body__form {
            width: 60%;
            padding-left: 43px;
        }
        
    }
}

.home-3 .newsletter {
    padding: 162px 0 99px;
}

.clients {
    padding: 139px 0 128px;

    @include tablet {
        padding: 100px 0;
    }
}
.home-2 .clients {
    padding: 139px 0 144px;
}
.technology {
    padding: 123px 0 0;
}
.home-3 .clients {
    padding: 129px 0 0;
}

.about .clients {
    padding: 111px 0 115px;
}

.page-clients .clients {
    padding-bottom: 0;
}

.testimonials {
    padding: 137px 0 142px;

    @include tablet {
        padding: 100px 0;
    }
}

.action {
    padding: 149px 0 122px;
    .action__body {
        background: $bg-item-3;
        border-radius: 20px;
        padding: 61px 72px;
        @include flex(center,space-between);

        @include tablet {
            flex-wrap: wrap;

            .block-text {
                margin-bottom: 30px;
            }
        }

        @include mobile {
            padding: 61px 30px;
        }

        .btn-action.style-2 {
            padding: 18px 60px;
            font-size: 20px;
        }
    }
}

.page-blog-single .action {
    padding: 168px 0 122px;
}

.page-contact .action {
    padding: 143px 0 122px;
}


.about .action {
    padding: 167px 0 122px;
}
.page-clients .action {
    padding: 57px 0 122px;
}

.home-2 .testimonial {
    padding: 140px 0 132px;
}

.home-3 .testimonial {
    padding: 105px 0 0;
}
.home-3 .section-counter {
    padding: 156px 0 0;
}
.blog {
    padding: 122px 0;
}

.home-3 .blog {
    padding: 137px 0 0;
}
.home-2 .partners {
    padding: 46px 0 119px;
}

.roadmap .faq {
    padding: 143px 0 0;
}
.page-faq .faq {
    padding: 143px 0 121px;
}

.home-3 .faq {
    padding: 140px 0 0;
}
.page-faq .action {
    padding: 167px 0 122px;
}

.visions {
    padding: 169px 0 167px;

    @include tablet {
        padding: 100px 0;
    }

    .group-image {
        margin-left: -130px;

        @include tablet {
            margin-left: 0;
            margin-top: 60px;
            img {
                width: 100%;
            }
        }
    }
}

.services {
    padding-top: 150px;
    .block-text {
        padding-top: 21px;  
            p {
                color: black;
            }  
        padding-right: 30px;   
        @include mobile {        
            padding-right: 0;            
            text-align: center;
            .sub-title {
            &::after {
                left: 45%;
            }}
          
        } 
    }
    .group-image {
        margin-left: 0px;
        @include mobile {                
                margin-right: -40px;
            }
        .image-single {
            width: 100%;
            @include mobile {
                margin-top: -50px;
                margin-bottom: 60px;
            }
            
        }
        @include tablet {
            margin-left: 0;
            margin-top: 60px;
            img {
                width: 100%;
            }
        }
      
    }
}

.collection {
    padding: 143px 0 149px;

    @include tablet {
        padding: 60px 0;
    }

    .group-image {
        padding-right: 200px;
        position: relative;

        @include tablet {
            padding-right: 0;
        }
    }
}

.collections .action {
    padding: 159px 0 122px;
}

.blog-list {
    padding: 168px 0 20px;
}

.blog-grid {
    padding: 168px 0 11px;
}

.contact {
    padding: 144px 0 168px; 
    @include mobile {
        .block-text {
            .title {
                font-size: 31px;
            }
        }
    }
}

@include tablet {
    .section-info {
        padding: 0;
    }
}

@include mobile {
    .action,
    .about .section-about,
    .about .action,
    .collections .action,
    .home-2 .road-map,
    .home-2 .clients ,
    .home-2 .testimonial,
    .roadmap .road-map,
    .page-blog-single .action,
    .page-faq .faq,
    .contact,
    .page-contact .action,
    .page-faq .action,
    .home-3 .road-map,
    .home-3 .clients,
    .home-3 .testimonial,
    .home-3 .newsletter {
        padding: 100px 0;
    }

    .about .clients {
        padding: 50px 0 40px;
    }

    .page-clients .action {
        padding: 0px 0 100px;
    }

    .roadmap .faq,
    .service .road-map,
    .blog-grid {
        padding-top: 100px;
    }

    .home-3 .montono , .technology, .home-3 .section-counter {
        padding: 0;
    }
    .home-3 .blog ,.home-3 .faq {
        padding: 100px 0 0;
    }

}