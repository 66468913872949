$body-bg: #45cd36;
$second-bg: #381463;
$main-color: #ffffff;
$main-color-hover: #f8c6dd;
$secondary-color: #e3014d;
$txt-color: #000;
$txt-second-color: #757474;
$rosa: #ffb8d7;
$rosa-fuerte: #f37081;
$rosa-claro: hsl(355, 100%, 94%);
$blanco: #ffffff;
$rojo: #d80b0b;
$menu-hover: #421717;

$font-main: "Gilroy-Bold";
$font-second: "Roboto";

$bg-item-1 : #ffb8d7;
$bg-item-2 : #ffb8d7;
$bg-item-3 : #ffb8d7;

$header-height: 170px;
$header-shrink-height: 90px;

$mobile-width: 767px;
$tablet-width: 1024px;
$desktop-width: 1200px;
$desktop-1350: 1350px;
$desktop-1500: 1500px;
$desktop-1900 : 1900px;


$box-shadow: 0 10px 20px rgb(0 0 0 / 5%);
$bg-overlay: rgba(0,0,0,0.5);